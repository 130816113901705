<template>
    <div v-if="profile">
        <PageHeader title="Profile"></PageHeader>
        <div class="wrapper profileWrapper ">

            <Header :profile="profile" @update="getProfile"/>

            <div class="tabs">
                <button class="tab" :key="key" :class="{ 'active' : key === selectedTab }" v-for="(tab, key) of tabs" @click="selectedTab = key">{{ tab.name }}</button>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '../../components/profile/Header.vue'
    export default {
        components: {
            Header
        },
        data() {
            return {
                profile: null,
                tabs: [
                    { name: 'Statistics', component: 'ProfileStatistics' },
                    { name: 'My customers', component: 'UserCustomers' }
                ],
                selectedTab: 0
            }
        },
        computed: {
            ...mapGetters(['user']),
            username() {
                return this.$route.params.username;
            }
        },
        methods: {
            getProfile() {
                if(!this.username) return;
                this.$axios.get(`/profile/${this.$route.params.username}`)
                .then(data => {
                    this.profile = data.data;
                });
            }
        },
        mounted() {
            this.getProfile();
        },
        watch: {
            username() {
                this.getProfile();
            }
        }
    }
</script>

<style lang="scss" scoped>
.profileWrapper {
    max-width: 992px;
    margin: 0 auto;
    display: grid;
    row-gap: 50px;
}


.tabs {
    border-bottom: 1px solid $borderColor;
    .tab {
        height: 44px;
        width: 200px;
        background: transparent;
        border: 0;
        color: $text;
        font-size: 14px;
        text-transform: uppercase;
        transition: ease 0.3s;
        position: relative;
        &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            content: '';
            width: 0;
            height: 3px;
            background: $buttonColor;
            transition: ease 0.3s;
        }
        &.active {
            color: $buttonColor;
            &::after {
                width: 100%;
                left: 0;
            }
        }
    }
}
</style>
<template>
    <div class="formDetailsHolder">

        <Input v-model="item.name" name="Dispatch name" :error="errors.name" placeholder="Dispatch name"/>
        <Input v-model="item.fullName" name="Full name" :error="errors.fullName" placeholder="Full name"/>

        <button class="default" @click="handleSaveChanges">Save changes</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        props: ['details'],
        data() {
            return {
                item: this.details ? JSON.parse(JSON.stringify(this.details)) : {},
                errors: {}
            }
        },
        methods: {
            handleSaveChanges() {
               this.errors = {};

                this.ajax('handleProfileUpdate', {
                    url: `/profile/DisplayDetails`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {

                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.$notify({
                        type: 'info',
                        text: `Profile has been successfully updated.`
                    });
                    
                    this.$emit('update');
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.formDetailsHolder {
    display: grid;
    row-gap: 10px;
}
</style>
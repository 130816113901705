<template>
    <div class="addUserForm">  

        <div class="tabs">
            <button :key="key" class="default small round" :class="{ 'reverse' : key != selectedTab }" @click="selectedTab = key" v-for="(tab, key) of tabs">{{ tab.name }}</button>
        </div>

        <div class="componentContent">
            <component v-bind:is="tabs[selectedTab].component" :details="newUser" @update="getProfileDetails"></component>
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainUserDetails from './MainUserDetails'
import LoginDetails from './LoginDetails'
import Password from './Password'

    export default {
        props: ['modelValue'],
        components: {
            MainUserDetails,
            LoginDetails,
            Password
        },
        data() {
            return {
                tabs: [
                    { name: 'Display details', component: 'MainUserDetails' },
                    { name: 'Login details', component: 'LoginDetails' },
                    { name: 'Password', component: 'Password' },
                ],
                selectedTab: 0,
                newUser: this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : { }
            }
        },
        computed: {
            ...mapGetters(['user'])
        },
        methods: {
            getProfileDetails() {
                this.ajax('handleProfileUpdate', {
                    url: `/profile/${this.newUser.username}`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this.newUser = body;
                    }
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.addUserForm {
    display: grid;
    row-gap: 20px;
    padding: 20px 0 0 0;
}

.tabs {
    display: flex;
    flex-wrap: wrap;
    button {
        margin: 0 5px 5px 0;
        border: 1px solid $buttonColor;
    }
}

.accessForm {
    display: grid;
    row-gap: 30px;
}

.image {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 0 auto;
}

</style>
<template>
    <div class="profileHeader">

        <ModalSimple v-if="user._id == profile._id" :hideButtons="true" v-model="editProfileModal" title="Edit Profile" :loading="isLoading(['handleProfileUpdate'])">
            <Edit v-model="profile" @update="$emit('update')"/>
        </ModalSimple>

        
        <div class="image" :style="`background-image: url('${profile.profileImage}')`"></div>

        <div class="about">

            <div class="fullname" v-if="profile.fullName">
                <h2>{{ profile.fullName }}</h2>
            </div>
            <div class="title">
                <h3>{{ profile.name }}</h3>
                <div>@{{ profile.username }}</div>
            </div>

            <div>{{ profile.position }}</div>

            <div class="stats">
                <div class="item"><strong>{{ profile.stats.loadsNum }}</strong> loads</div>
                <div class="item"><strong>${{ priceConverter(profile.stats.gross) }}</strong> in gross</div>
            </div>

            <div class="action">
                <button class="default small" @click="editProfileModal = true" v-if="user._id == profile._id">Edit Profile</button>
                <button class="default small reverse" @click="changeProfilePicture" v-if="user._id == profile._id">Change picture</button>
                <input type="file" name="photo" ref="photo" accept="image/png, image/jpg, image/jpeg, image/webp" @change="uploadProfilePhoto" hidden>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Edit from './Edit.vue'
import UploadFileMixin from '../../mixins/UploadFile'

    export default {
        mixins: [UploadFileMixin],
        components: {
            Edit
        },
        props: ['profile'],
        computed: {
            ...mapGetters(['user']),
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            image() {
                if(!this.profile.image || !this.profile.image.path) return '';
                return this.apiURL + this.profile.image.path;
            }
        },
        data() {
            return {
                editProfileModal: false,
                imageUploadModal: false
            }
        },
        methods: {
            uploadProfilePhoto() {
                this.fileSelected('photo', 'photo', '/profile/UploadPicture', (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$emit('update');
                });
            },
            changeProfilePicture() {
                this.$refs['photo'].click();
            },
            runChangePasswordForm() {
                this.editProfileModal = false;
                alert("Changing password");
            }
        },
    }
</script>

<style lang="scss" scoped>
.profileHeader {
    display: grid;
    grid-template-columns: 200px minmax(0, 1fr);
    column-gap: 100px;
}

.image {
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    background-color: #eee;
}

.stats {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: start;
    justify-content: start;
    column-gap: 20px;
    font-size: 18px;
}

.action {
    display: flex;
    align-items: center;
    button {
        margin-right: 20px;
    }
}

.about {
    display: grid;
    row-gap: 20px;
    align-items: start;
    align-content: start;
    .title {
        display: grid;
        align-items: center;
        grid-template-columns: auto auto;
        column-gap: 10px;
        justify-items: start;
        justify-content: start;
    }
}

</style>
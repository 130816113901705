<template>
    <div class="formDetailsHolder">

        <Input v-model="item.password" type="password" name="Password" :error="errors.password" placeholder="Password"/>
        <Input v-model="item.repeatPassword" type="password" name="Repeat password" :error="errors.repeatPassword" placeholder="Repeat password"/>

        <button class="default" @click="handleSaveChanges">Change password</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        data() {
            return {
                item: {},
                errors: {}
            }
        },
        methods: {
            handleSaveChanges() {
                this.errors = {};

                 this.ajax('handleProfileUpdate', {
                    url: `/profile/ChangePassword`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {

                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.$notify({
                        type: 'info',
                        text: `Password has been successfully changed.`
                    });
                    
                    this.item = {}
                    this.$emit('update');
                });
            }
        },
        computed: {
            ...mapGetters(['positions'])
        }
    }
</script>

<style lang="scss" scoped>
.formDetailsHolder {
    display: grid;
    row-gap: 10px;
}
</style>
<template>
    <div class="formDetailsHolder">

        <Input v-model="item.email" name="Email address" :error="errors.email" placeholder="Email address"/>
        <Input v-model="item.username" name="Username" :error="errors.username" placeholder="Username"/>
        <Input v-model="item.pin" name="PIN" :error="errors.pin" placeholder="PIN (6 digits)"/>

        <button class="default" @click="handleSaveChanges">Save changes</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        props: ['details'],
        data() {
            return {
                item: this.details ? JSON.parse(JSON.stringify(this.details)) : {},
                errors: {}
            }
        },
        methods: {
            handleSaveChanges() {
                this.errors = {};

                 this.ajax('handleProfileUpdate', {
                    url: `/profile/LoginDetails`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {

                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.$notify({
                        type: 'info',
                        text: `Profile has been successfully updated.`
                    });
                    
                    this.$emit('update');
                });
            }
        },
        computed: {
            ...mapGetters(['positions'])
        }
    }
</script>

<style lang="scss" scoped>
.formDetailsHolder {
    display: grid;
    row-gap: 10px;
}
</style>